import { SVGProps } from 'react';

export const InfoSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path fill="#2F353E" d="M0 0h26v26H0z" />
    <path
      fill="#fff"
      d="M-477-98a2 2 0 0 1 2-2h976a2 2 0 0 1 2 2v251a2 2 0 0 1-2 2h-976a2 2 0 0 1-2-2V-98Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.1}
      d="M-475-99h976v-2h-976v2Zm977 1v251h2V-98h-2Zm-1 252h-976v2h976v-2Zm-977-1V-98h-2v251h2Zm1 1a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3v-2Zm977-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2Zm-1-252a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2Zm-976-2a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2Z"
    />
    <path
      fill="#5B5A5A"
      fillRule="evenodd"
      d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Zm0-1.2c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8C7.035 2.2 2.2 7.035 2.2 13c0 5.965 4.835 10.8 10.8 10.8Zm.998-14.934a1.2 1.2 0 1 0-1.994-1.333 1.2 1.2 0 0 0 1.994 1.333Zm-3.337 2.627a.799.799 0 0 0 .739 1.106h.8V17.4h-.8a.8.8 0 0 0 0 1.6h3.2a.8.8 0 0 0 0-1.6h-.8V11.8a.8.8 0 0 0-.8-.8h-1.6a.799.799 0 0 0-.74.494Z"
      clipRule="evenodd"
    />
  </svg>
);

export const ErrorSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#5B5A5A"
      d="M13 6.957a1.15 1.15 0 0 1 1.127 1.126v5.252a1.126 1.126 0 0 1-2.251 0V8.083A1.15 1.15 0 0 1 13 6.957Zm1.5 10.505a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM1 9.977a3.374 3.374 0 0 1 .989-2.387l5.6-5.601A3.374 3.374 0 0 1 9.976 1h6.05a3.375 3.375 0 0 1 2.386.989L24.01 7.59A3.374 3.374 0 0 1 25 9.977v6.047a3.376 3.376 0 0 1-.99 2.387l-5.598 5.6a3.375 3.375 0 0 1-2.387.989H9.976a3.375 3.375 0 0 1-2.387-.99l-5.6-5.596A3.374 3.374 0 0 1 1 16.024V9.977Zm2.58-.797c-.21.212-.329.499-.33.797v6.047c.001.298.12.583.33.793l5.6 5.606c.212.21.499.329.797.329h6.049c.297-.008.58-.125.797-.329l5.599-5.604c.21-.21.328-.495.328-.792v-6.05c0-.298-.118-.585-.328-.797l-5.6-5.6a1.213 1.213 0 0 0-.797-.329H9.976c-.299 0-.585.12-.797.33L3.58 9.18Z"
    />
  </svg>
);

export const NotificationAlertSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <circle cx={13} cy={13} r={12} fill="#E40203" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13 4.79a1.895 1.895 0 0 0-1.895 1.894v7.58a1.895 1.895 0 0 0 3.79 0v-7.58A1.895 1.895 0 0 0 13 4.79Zm0 16.42a1.895 1.895 0 1 0 0-3.789 1.895 1.895 0 0 0 0 3.79Z"
      clipRule="evenodd"
    />
  </svg>
);

export const SuccessSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#5B5A5A"
      d="M12.945 25A11.949 11.949 0 0 1 1.229 10.724a11.947 11.947 0 0 1 3.268-6.117A11.947 11.947 0 1 1 21.393 21.5a11.865 11.865 0 0 1-8.448 3.5Zm-6.91-13.096a1.15 1.15 0 0 0-.814 1.962l4.61 4.606a1.152 1.152 0 0 0 1.624 0l9.211-9.21a1.15 1.15 0 1 0-1.625-1.626l-8.43 8.394-3.76-3.787a1.143 1.143 0 0 0-.815-.34Z"
    />
  </svg>
);

export const WarningSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path fill="#2F353E" d="M0 0h26v26H0z" />
    <path
      fill="#fff"
      d="M-289-98a2 2 0 0 1 2-2h976a2 2 0 0 1 2 2v251a2 2 0 0 1-2 2h-976a2 2 0 0 1-2-2V-98Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.1}
      d="M-287-99h976v-2h-976v2Zm977 1v251h2V-98h-2Zm-1 252h-976v2h976v-2Zm-977-1V-98h-2v251h2Zm1 1a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3v-2Zm977-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2Zm-1-252a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2Zm-976-2a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2Z"
    />
    <path
      fill="#5B5A5A"
      d="M11.434 2.91 1.213 21.428a1.731 1.731 0 0 0 .689 2.354c.267.147.569.223.875.219h20.449a1.77 1.77 0 0 0 1.521-.843 1.736 1.736 0 0 0 .043-1.73L14.563 2.912a1.802 1.802 0 0 0-3.128 0Zm2.58 7.684-.208 6.99H12.19l-.208-6.99h2.032ZM13 21.387a1.067 1.067 0 0 1-.953-.678 1.047 1.047 0 0 1 .255-1.136 1.065 1.065 0 0 1 1.153-.21 1.06 1.06 0 0 1 .647.97 1.06 1.06 0 0 1-.685.983 1.079 1.079 0 0 1-.417.071Z"
    />
  </svg>
);
