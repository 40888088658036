export const languages = [
  { name: 'English', code: 'en' },
  { name: 'Español', code: 'es' },
  { name: 'Français', code: 'fr' },
  { name: '한국어', code: 'ko' },
  { name: 'Deutsch', code: 'de' },
  { name: 'Italiano', code: 'it' },
  { name: '汉语族', code: 'zh-Hans-CN' },
  { name: '日本語', code: 'ja' },
  { name: 'Русский', code: 'ru' },
  { name: 'ภาษาไทย', code: 'th' },
  { name: 'Türkçe', code: 'tr' },
  { name: 'Tiếng Việt', code: 'vi' },
  { name: 'Magyar nyelv', code: 'hu' },
  { name: 'Svenska', code: 'sv' },
  { name: 'Hrvatski', code: 'hr' },
  { name: 'Slovenščina', code: 'sl' },
];
