import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'joi';
import { Box, Stack, Typography } from '@mui/material';
import { frontendRoutes, ResponseCode } from '../../config';
import apis from '../../repositories/api';
import { logInfo, logUserAction } from '../../services';
import { UserActions } from '../../models/Logs';
import { validatePassword } from '../../utils';
import {
  AuthErrorMessage,
  DigitInput,
  InputField,
  WideButton,
} from '../../components';
import { logErrorType } from '../../utils/errors/commonErrorLogging';
import { PasswordInput } from '../../components/Password';

export const ResetPasswordConfirm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [code, setCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const defaultDigits = Array(6).fill('');
  const [digits, setDigits] = useState<string[]>(defaultDigits);
  const [statusCode, setStatusCode] = useState<number>(1);
  const { t } = useTranslation();
  const digitRefs = useRef<Array<HTMLInputElement>>([]);

  useEffect(() => {
    if (state?.email) {
      setEmail(state.email);
    } else if (searchParams.has('email')) {
      setEmail(searchParams.get('email') as string);
    }

    if (searchParams.has('code')) {
      setCode(searchParams.get('code') as string);
    }
  }, [state, searchParams]);

  const onInput = (id: string, value: string) => {
    switch (id) {
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      case 'email':
        setEmail(value);
        break;
    }
  };

  const onChangePassword = async () => {
    logUserAction({
      message: UserActions.button,
      context: {
        button: 'change-password-confirm',
        isEqual: password === confirmPassword,
      },
    });

    try {
      const validPassword = await validatePassword(password, confirmPassword);
      if (validPassword && code) {
        const {
          data: { status },
        } = await apis.resetPasswordConfirm({
          email: email,
          code,
          password,
        });
        switch (status) {
          case ResponseCode.success:
            navigate(frontendRoutes.resetPasswordComplete);
            break;
          case ResponseCode.error:
            setErrorMessage(t('login_signup.reset_password.invalid_code'));
            break;
          case ResponseCode.networkError:
            setErrorMessage(t('common.notice.servers_unavailable'));
            return;
          default:
            setErrorMessage(t('common.notice.default_error_msg'));
        }
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        if (error.details[0].path[0] === 'password') {
          setPasswordError(t('common.notice.password_minimum'));
          setConfirmPasswordError('');
          logInfo({
            message: 'Password must be a minimum of 6 characters',
            context: { passwordLength: password.trim().length },
          });
        } else {
          setConfirmPasswordError(t('common.notice.password_do_not_match'));
          setPasswordError('');
          logInfo({
            message: 'The passwords entered do not match.',
            context: { login: email },
          });
        }
      } else {
        setErrorMessage(t('common.notice.default_error_msg'));
        logErrorType(error, 2015, {
          email,
          button: 'change-password',
        });
      }
    }
  };

  const onSendRequest = async () => {
    logUserAction({
      message: UserActions.button,
      context: {
        button: 'send-reset-code-request',
        email,
      },
    });

    try {
      setDigits(defaultDigits);

      await apis.resetPasswordRequest(email);
    } catch (error) {
      logErrorType(error, 9005, { email, action: 'Reset Password Request' });
    }
  };

  const focusNextInput = (index: number) => {
    if (digitRefs.current[index + 1]) {
      digitRefs.current[index + 1]?.focus();
    }
  };

  const handleChange = (index: number, value: string) => {
    if (/^\d?$/.test(value)) {
      const code = [...digits];
      code[index] = value;

      setDigits(code);
      setCode(code.join(''));

      if (value !== '' && index < digits.length - 1) {
        focusNextInput(index);
      }
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      const confirmCode = async () => {
        try {
          const {
            data: { status },
          } = await apis.checkResetCode({ email, code });
          return status;
        } catch (error) {
          logErrorType(error, 9005, { email, action: 'Check Reset Code' });
        }
      };

      confirmCode().then((status) => setStatusCode(Number(status)));
    }
  }, [code]);

  const handleBackspace = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === 'Backspace') {
      if (digits[index] === '') {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          setDigits((prevDigits) => {
            const updatedDigits = [...prevDigits];
            updatedDigits[prevIndex] = '';
            return updatedDigits;
          });
          digitRefs.current[prevIndex]?.focus();
        }
      } else {
        setDigits((prevDigits) => {
          const updatedDigits = [...prevDigits];
          updatedDigits[index] = '';
          return updatedDigits;
        });
      }

      const newCode = digits.filter((_, i) => i !== index).join('');
      setCode(newCode);
      setStatusCode(1);
    }
  };

  const handleArrowKeys = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === 'ArrowLeft') {
      digitRefs.current[index - 1]?.focus();
    } else if (event.key === 'ArrowRight') {
      digitRefs.current[index + 1]?.focus();
    }
  };

  return (
    <>
      <Typography
        component='h3'
        variant={'h3regular'}
        sx={{ color: 'text.primary' }}
      >
        {t('login_signup.reset_password.enter_code')}
      </Typography>
      <Typography
        variant={'bodyLarge'}
        sx={{ color: 'secondary.contrastText' }}
        textAlign='center'
      >
        {t('login_signup.reset_password.message')}
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        {digits.map((digit, index) => (
          <Box key={index}>
            <DigitInput
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => {
                handleBackspace(e, index);
                handleArrowKeys(e, index);
              }}
              inputRef={(ref) => {
                if (ref) {
                  digitRefs.current[index] = ref;
                }
              }}
            />
          </Box>
        ))}
      </Box>
      <Typography
        variant='bodyLarge'
        align='center'
        onClick={onSendRequest}
        sx={{
          cursor: 'pointer',
          '&:hover': { color: 'tertiary.light' },
          color: 'tertiary.main',
        }}
      >
        {t('login_signup.reset_password.resend_code')}
      </Typography>
      <Typography
        component='h3'
        variant={'h3semiBold'}
        sx={{
          color: !code || !!statusCode
            ? 'text.neutral500'
            : 'text.primary',
        }}
        textAlign='center'
      >
        {t('login_signup.reset_password.set_new_password')}
      </Typography>
      <Stack width='100%' spacing={2}>
        {!state?.email && (
          <InputField
            id='email'
            label={t('common.field_label.email')}
            error={!email?.trim()}
            value={email}
            onChange={onInput.bind(this, 'email')}
          />
        )}
        <PasswordInput
          disabled={!!statusCode}
          label={t('common.field_label.password')}
          error={!!passwordError}
          helperText={passwordError}
          value={password}
          onChange={onInput.bind(this, 'password')}
        />
        <PasswordInput
          label={t('common.field_label.confirm_password')}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
          value={confirmPassword}
          onChange={onInput.bind(this, 'confirmPassword')}
        />
        <AuthErrorMessage
          message={errorMessage}
          visible={Boolean(errorMessage)}
        />
        <WideButton
          disabled={!!statusCode}
          message={t('common.button.save')}
          onClick={onChangePassword}
        />
      </Stack>
    </>
  );
};
