import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { isEmail, RFNGApiError, trimObjectValues } from '../../utils';
import {
  COOKIE_CLIENT_ID,
  COOKIE_GROUP_ID,
  COOKIE_VALID_GROUP_ID,
  frontendRoutes,
  ResponseCode,
  GROUP_CODE,
} from '../../config';
import apis from '../../repositories/api';
import { SignupState } from './types';
import { logErrorType } from '../../utils/errors/commonErrorLogging';
import { Logo, WideButton, InputField } from '../../components';
import { useTranslation } from 'react-i18next';
import { useBranding } from '../../hooks';
import SpinLoader from '../../components/UI/loader/SpinLoader';
import { logInfo } from '../../services';

export const SignUpPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { groupCode, clientId } = useParams();
  const {
    fetchThemeByGroup,
    brandingLoaded,
    setBrandingLoaded,
  } =
    useBranding();
  const [login, setLogin] = useState('');
  const [loginError, setLoginError] = useState('');
  const [groupCodeValid, setGroupCodeValid] = useState(false);
  const [groupCodeNew, setGroupCodeNew] = useState(
    () => groupCode || sessionStorage.getItem(GROUP_CODE) || undefined
  );
  const [, setCookie, removeCookie] = useCookies([
    COOKIE_CLIENT_ID,
    COOKIE_GROUP_ID,
    COOKIE_VALID_GROUP_ID,
  ]);

  useEffect(() => {
    // RFNG-921: if user back to signup page from the next signup pages, we need to clear the signup method.
    localStorage.removeItem('rfToken');
    removeCookie(COOKIE_GROUP_ID, { path: '/' });
    removeCookie(COOKIE_VALID_GROUP_ID, { path: '/' });
  }, []);


  useEffect(() => {
    if (groupCode) {
      sessionStorage.setItem(GROUP_CODE, groupCode);
      setGroupCodeNew(groupCode);
      fetchThemeByGroup(groupCode).then((res) => setGroupCodeValid(res));
    } else {
      removeCookie(COOKIE_GROUP_ID, { path: '/' });
      removeCookie(COOKIE_VALID_GROUP_ID, { path: '/' });
      setBrandingLoaded(true);
    }
  }, [groupCode, clientId]);

  const handleSignupEmailPhone = async () => {
    if (groupCode) {
      setCookie(COOKIE_GROUP_ID, groupCode, { path: '/' });
    }

    const localLoginCheck = await isEmail(login);

    if (!localLoginCheck) {
      setLoginError(t('common.notice.invalid_email_msg'));
      return;
    }

    const apiLoginCheck = apis.checkEmail({ email: login });

    apiLoginCheck.then(async ({ data: { status } }) => {
      if (status === ResponseCode.success) {
        const state: SignupState = {
          login,
          groupCode: groupCodeNew,
          clientId,
          groupCodeValid,
        };
        if (await isEmail(login)) {
          state.email = login;
        }
        navigate(frontendRoutes.signupName, { state });
      } else if (status == ResponseCode.userExist) {
        navigate(frontendRoutes.signupExistPassword, {
          state: trimObjectValues({ email: login, groupCode }),
        });
      } else if (status === ResponseCode.networkError) {
        setLoginError(t('common.notice.servers_unavailable'));
        return;
      } else if (status === ResponseCode.invalidEmail) {
        setLoginError(t('common.notice.invalid_email_msg'));
        logInfo({
          message: 'Email validation failed',
          context: {
            status: ResponseCode.invalidEmail,
            email: login,
          },
        });
        return;
      } else {
        throw new RFNGApiError(3000, status);
      }
    }).catch((error) => {
      const context = { login, status: error?.status };

      logErrorType(error, 4006, context);
      setLoginError(t('common.notice.default_error_msg'));
    });
  };


  if (!brandingLoaded) {
    return <SpinLoader />;
  }

  return (
    <>
      <Logo width="60%" />
      <Typography
        component="h2"
        variant="h2regular"
        textAlign="center"
        sx={{ color: 'primary.light' }}
      >
        {t('login_signup.enter_email.header_msg')}
      </Typography>
      <Stack width="100%">
        <InputField
          id='signup-email-phone'
          label={t('common.field_label.email')}
          value={login}
          onChange={setLogin}
          error={!!loginError}
          helperText={loginError}
        />
      </Stack>
      <Stack flex={1} width="100%" gap={2}>
        <WideButton
          disabled={!login.trim()}
          message={t('common.button.next')}
          onClick={handleSignupEmailPhone}
        />
      </Stack>
    </>
  );
};
