import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import {
  COOKIE_GROUP_ID,
  COOKIE_VALID_GROUP_ID,
  frontendRoutes,
  ResponseCode,
} from '../../config';
import apis from '../../repositories/api';
import { useBranding } from '../../hooks';
import { SignupState } from './types';
import { useGroupCodeValidity } from './hooks/useGroupCodeValidity';
import { InputField, SpinLoader, WideButton } from '../../components';
import { trimObjectValues } from '../../utils';

export const SignupMissedDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  // Typing the state as SignupState
  const state = location.state as SignupState;
  const { addAccount, token, email, dataName, dataTitle, message } = state;
  const { fetchThemeByGroup } = useBranding();
  const [missedDetails, setMissedDetails] = useState<string>(
    state.groupCode ?? ''
  );
  const [loginError, setLoginError] = useState('');
  const [, setCookie, removeCookie] = useCookies([
    COOKIE_VALID_GROUP_ID,
    COOKIE_GROUP_ID,
  ]);
  const navigate = useNavigate();
  const { checkGroupCode, loginError: groupCodeError } =
    useGroupCodeValidity(state);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!email) {
      navigate(frontendRoutes.signup, { replace: true });
    }
    // if group code already defined in the URL
    if (missedDetails.trim()) groupCodeHandler();
  }, []);

  useEffect(() => {
    setLoginError(groupCodeError);
  }, [groupCodeError]);

  const handleMissedDetails = (value: string) => {
    setMissedDetails(value);
    setLoginError('');
  };

  const addAccountHandler = async () => {
    const {
      data: { status, accounts },
    } = await apis.addAccount(token ?? '', { groupCode: missedDetails });

    if (status === ResponseCode.success) {
      navigate(frontendRoutes.signupFinish, {
        state: trimObjectValues({ accounts, token }),
      });
    } else {
      setLoginError(t('login_signup.notice.general_user_create_error'));
    }
  };

  const groupCodeHandler = async () => {
    setIsLoading(true);
    try {
      if (email && (await checkGroupCode(missedDetails, email))) {
        if (addAccount) {
          await addAccountHandler();
          return;
        }

        setCookie(COOKIE_VALID_GROUP_ID, true, { path: '/' });
        removeCookie(COOKIE_GROUP_ID, { path: '/' });
        await fetchThemeByGroup(missedDetails);
        const newState: SignupState = {
          ...state,
          groupCode: missedDetails,
          groupCodeValid: true,
        };
        navigate(frontendRoutes.signupAdditionalInfo, {
          state: trimObjectValues(newState),
          // If the group code is predefined, we don't need to go back to this page
          replace: missedDetails === state.groupCode,
        });
      }
    } catch (error) {
      setLoginError(t('common.notice.default_error_msg'));
    }
    setIsLoading(false);
  };

  const onNext = async () => {
    if (!missedDetails.trim()) {
      setLoginError(t('common.notice.required'));
      return;
    }

    await groupCodeHandler();
  };

  if (isLoading) {
    return <SpinLoader />;
  }

  return (
    <>
      <Stack width="100%" spacing={2}>
        <Typography
          component='h2'
          variant='h2regular'
          sx={{ color: 'primary.main', }}
          textAlign='center'
        >
          {dataTitle ?? t('login_signup.group_code.header_msg_desktop')}
        </Typography>
        <Typography
          component="h3"
          variant="h3regular"
          fontSize="1rem"
          sx={{ color: 'primary.secondary' }}
        >
          {message ?? ''}
        </Typography>
      </Stack>
      <Stack width='100%' spacing={8}>
        <Stack spacing={6} mt={3}>
          <InputField
            type={dataName}
            label={t('common.field_label.group_code')}
            value={missedDetails}
            onChange={handleMissedDetails}
            error={!!loginError}
            helperText={loginError}
          />
          <Typography variant="bodyLarge" color="text.primary" align="center">
            {t('login_signup.group_code.sub_header_msg')}
          </Typography>
        </Stack>
        <WideButton
          disabled={!missedDetails?.trim()}
          message={t('common.button.next')}
          onClick={onNext}
        />
      </Stack>
    </>
  );
};
