import { Link, useBlocker, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import { frontendRoutes } from '../../config';
import { useMemo } from 'react';
import { SignupState } from './types';
// import { QrCodeIcon, SendToPhoneIcon } from '../../assets/icons';

const ButtonLink = styled(Link)`
  display: inline-block;
  padding: 12px 24px;
  color: #156beb;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #508de7;
  }
`;

export const SignUpFinish = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { login } = useMemo(() => state as SignupState, [state]);

  useBlocker(({ nextLocation: { pathname } }) => {
    // block navigation to the summary page after signup
    return pathname === frontendRoutes.signupSummary;
  });

  const firstAccountToken = state.accounts[0].token;

  // const isIOS = () => {
  //   return /iPad|iPhone|iPod/.test(navigator.userAgent);
  // };

  // const isAndroid = () => {
  //   return /android/i.test(navigator.userAgent);
  // };

  const redirectPath =
    state.accounts.length > 1
      ? frontendRoutes.accountSelection
      : `${frontendRoutes.legacyDashboard}${firstAccountToken}`;

  return (
    <Stack
      width={'100%'}
      minHeight={'500px'}
      alignItems="center"
      justifyContent="space-between"
    >
      {!isMobile && (
        <>
          <Stack width={'100%'} alignItems="center" gap="-1rem">
            <Typography
              align="center"
              component="h3"
              variant="h3regular"
              color={'primary.main'}
            >
              {t('login_signup.post_signup.header')}
            </Typography>
            <Typography
              align="center"
              component="h3"
              variant="h3regular"
              color={'primary.main'}
            >
              {t('login_signup.post_signup.account_ready_msg')}
            </Typography>
          </Stack>

          <Stack width={'100%'} alignItems="center" gap="2rem" mt={5}>
            <img
              src="/images/rf_logo/rapidfunnel-2.jpg"
              width="112px"
              alt="Rapid Funnel Logo"
            />
            <Typography
              variant="h2semiBold"
              align="center"
              color={'primary.main'}
            >
              {t('login_signup.post_signup.for_best_experience_msg')}
            </Typography>
          </Stack>

          <Stack width={'100%'} alignItems="center" gap="2rem" paddingTop={3}>
            {/* <Typography align="center" component="h3" variant="h3regular">
              {t('login_signup.post_signup.get_the_mobile_app')}
            </Typography> */}
            {/* <Stack
              justifyContent="space-around"
              gap="2rem"
              direction={'row'}
              width="537px"
            >
              {/* <QrCodeIcon /> */}
            {/* <Box
                sx={{
                  width: '1px',
                  height: '112px',
                  backgroundColor: 'text.disabled',
                  marginLeft: '20px',
                }}
              /> */}
            {/* <Box
                sx={{
                  display: 'flex',
                  alignItem: 'center',
                  paddingTop: '12px',
                }}
              >
                <SendToPhoneIcon />
              </Box>
              <Typography
                variant="bodyLarge"
                color="tertiary.main"
                noWrap={true}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                  marginLeft: '-30px',
                  cursor: 'pointer',
                }}
              >
                {t('login_signup.post_signup.send_me_the_link')}
              </Typography>
            </Stack> */}
            <Box
              sx={{
                width: '562px',
                height: '1px',
                backgroundColor: 'text.disabled',
                textAlign: 'center',
              }}
            ></Box>
            <Stack width={'100%'} alignItems="center" gap="-1rem">
              <Typography
                variant="bodySmall"
                align="center"
                color={'text.primary'}
              >
                {t('login_signup.post_signup.remember_to_login_with')}
              </Typography>
              <Typography
                variant="bodyLarge"
                align="center"
                color={'text.primary'}
                mb={-5}
              >
                {login}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}

      {isMobile && (
        <>
          <Stack width={'100%'} alignItems="center" gap="-1rem" mt={10}>
            <Typography
              align="center"
              component="h3"
              variant="h3regular"
              color={'primary.main'}
            >
              {t('login_signup.post_signup.header')}
            </Typography>
            <Typography
              align="center"
              component="h3"
              variant="h3regular"
              color={'primary.main'}
            >
              {t('login_signup.post_signup.account_ready_msg')}
            </Typography>
          </Stack>

          <Stack width={'100%'} alignItems="center" gap="3rem">
            <Stack justifyContent="center" width="80px" height="80px" mt={5}>
              <img src={theme.extra.appIconImage} alt="App Icon" />
            </Stack>
            <Typography
              align="center"
              component="h3"
              variant="h3regular"
              color={'primary.main'}
            >
              {t('login_signup.post_signup.for_best_experience_msg')}
            </Typography>
            {/* <Typography
              variant="h3semiBold"
              component="h3"
              align="center"
              mt={1}
            >
              {t('login_signup.post_signup.get_the_mobile_app')}
            </Typography> */}
          </Stack>
        </>
      )}

      <Box textAlign="center" position={'relative'} bottom={-100}>
        <ButtonLink
          sx={{ typography: 'bodySmall' }}
          target="_self"
          to={redirectPath}
          state={state}
        >
          {t('login_signup.post_signup.login_with_browser')}
        </ButtonLink>
      </Box>
    </Stack>
  );
};
