import { Button as MUIButton } from '@mui/material';

type ButtonType = {
  color?: string;
  variant?: 'contained' | 'outlined';
  textTransform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize';
  fontSize?: string;
  border?: string;
};

const buttonTypes: Record<string, ButtonType> = {
  regular: {
    color: 'primary',
    variant: 'contained',
    textTransform: 'none',
    border: 'none',
  },
  outlined: {
    color: 'secondary',
    variant: 'outlined',
    textTransform: 'none',
    border: '1px solid',
  },
};

export const Button = ({
  type,
  children,
  padding,
  margin,
  onClick,
}: {
  type: keyof typeof buttonTypes;
  children: React.ReactNode;
  onClick?: () => void;
  padding?: string;
  margin?: string;
}) => {
  const { color, textTransform, border, fontSize, variant } = buttonTypes[type];

  return (
    <MUIButton
      style={{ color, textTransform, border, padding, margin, fontSize }}
      variant={variant}
      onClick={onClick}
      sx={{ typography: 'buttonRegular' }}
    >
      {children}
    </MUIButton>
  );
};
