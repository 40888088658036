import { PaletteOptions } from '@mui/material/styles';

export const defaultPalette: PaletteOptions = {
  primary: {
    main: '#77B800',
    light: '#77B800',
    dark: '#77B800',
    contrastText: '#fff',
    translucent: 'rgba(118,118,118,0.18)',
  },
  secondary: {
    main: '#FFF',
    contrastText: '#767676',
  },
  tertiary: {
    main: '#0096db',
    light: '#00afff',
  },
  error: {
    main: '#ED9400'
  },
  text: {
    primary: '#252525',
    secondary: '#767676',
    disabled: '#E2E0E0',
    neutral100: '#ffffff',
    neutral200: '#f7f7f7',
    neutral300: '#e2e0e0',
    neutral400: '#c7c6c6',
    neutral500: '#adadad',
    neutral600: '#767676',
    neutral700: '#5b5a5a',
    neutral800: '#404040',
    neutral900: '#252525',
  },
};
