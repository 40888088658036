import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { logError } from '../services';
import { languages } from './i18n.languages';

type LanguageResources = Record<string, { translation: object }>;

const genLanguageResources = () => {
  const resources: LanguageResources = {};
  languages.forEach(({ code }) => {
    resources[code] = { translation: require(`../assets/i18n/${code}.json`) };
  });
  return resources;
};

const logMissingKeys = (
  languages: readonly string[],
  namespace: string,
  key: string
) => {
  logError({
    errorCode: 4032,
    message: 'No translation key found',
    context: { languages, namespace, key },
  });
};

i18n.use(initReactI18next).init({
  resources: genLanguageResources(),
  lng: 'en',
  fallbackLng: 'en',
  react: {
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
  },
  saveMissing: true,
  missingKeyHandler: logMissingKeys,
});

export default i18n;
