export const config = {
  server_url: process.env.REACT_APP_API ?? '/api/',
  lumen_url: process.env.REACT_APP_LUMEN_API_URL,
  loglevel: process.env.REACT_APP_LOGLEVEL || 'info',
  mixpanel_token: process.env.REACT_APP_MIXPANEL_TOKEN || '',
  legacy_url: process.env.REACT_APP_LEGACY_WEB || '',
  version: process.env.REACT_APP_VERSION,
};

export const smartConnectorStatus = {
  isActionDue: 1,
  isWaitingForResponse: 2,
  isWaitingForDelay: 3,
};

export const backendRoutes: Record<string, string> = {
  session: `${config.server_url}auth/session`,
  loginFacebook: `${config.server_url}api/auth/facebook?type=login`,
  signupFacebook: `${config.server_url}api/auth/facebook?type=signup`,
  loginGoogle: `${config.server_url}api/auth/google?type=login`,
  signupGoogle: `${config.server_url}api/auth/google?type=signup`,
  loginApple: `${config.server_url}api/auth/apple?type=login`,
  signupApple: `${config.server_url}api/auth/apple?type=signup`,
  logout: `${config.server_url}api/auth/logout`,
  logs: `${config.server_url}api/log`,
};

export const frontendRoutes = {
  home: '/',
  login: '/login',
  resetPasswordRequest: '/reset-password-request',
  resetPasswordConfirm: '/reset-password-confirm',
  resetPasswordComplete: '/reset-password-complete',
  resetPasswordLink: '/password-reset',
  updatePasswordMultiAccount: '/update-password',
  accountSelection: '/account-selection',
  signup: '/signup',
  signupExistPassword: '/signup/exist-password',
  signupPassword: '/signup/password',
  signupName: '/signup/name',
  signupMissed: '/signup/missed',
  signupAdditionalInfo: '/signup/additional',
  signupSummary: '/signup/summary',
  signupFinish: '/signup/finish',
  dashboard: '/auth/dashboard',
  legacyDashboard: `${config.legacy_url}sso/login/index?loginToken=`,
};

export enum RevisionCode {
  default,
  pwdUnify = 1,
}
