import { Button } from '@mui/material';

export const WideButton = ({ message, onClick, disabled }: {
  message: string,
  onClick: () => void,
  disabled?: boolean
}) => {
  return (
    <Button
      sx={{
        typography: 'buttonRegular',
        textTransform: 'none',
        '&.Mui-disabled': {
          color: 'primary.contrastText',
          opacity: 0.3,
          backgroundColor: 'primary.main',
        },
      }}
      disabled={disabled}
      variant='contained'
      size='large'
      fullWidth
      onClick={onClick}>
      {message}
    </Button>
  );
};
