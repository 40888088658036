import { TypographyOptions } from '@mui/material/styles/createTypography';
import { BreakPoints } from './themeTypes';

enum FontWeight {
  regular = 400,
  semiBold = 600,
  bold = 700
}

enum FontFamily {
  sans = 'Open Sans',
}

export const defaultTypography: TypographyOptions = {
  fontFamily: FontFamily.sans,
  h1regular: {
    fontSize: '60px',
    [BreakPoints.mobile]: {
      fontSize: '56px',
    },
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.sans,
  },
  h2regular: {
    fontSize: '48px',
    [BreakPoints.mobile]: {
      fontSize: '32px',
    },
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.sans,
  },
  h3regular: {
    fontSize: '36px',
    [BreakPoints.mobile]: {
      fontSize: '24px',
    },
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.sans,
  },
  buttonRegular: {
    fontSize: '32px',
    [BreakPoints.mobile]: {
      fontSize: '24px',
    },
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.sans,
  },
  inputLabel: {
    fontSize: '20px',
    [BreakPoints.mobile]: {
      fontSize: '16px',
    },
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.sans,
  },
  inputField: {
    fontSize: '36px',
    [BreakPoints.mobile]: {
      fontSize: '24px',
    },
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.sans,
  },
  h1semiBold: {
    fontSize: '60px',
    [BreakPoints.mobile]: {
      fontSize: '56px',
    },
    fontWeight: FontWeight.semiBold,
    fontFamily: FontFamily.sans,
  },
  h2semiBold: {
    fontSize: '48px',
    [BreakPoints.mobile]: {
      fontSize: '32px',
    },
    fontWeight: FontWeight.semiBold,
    fontFamily: FontFamily.sans,
  },
  h3semiBold: {
    fontSize: '36px',
    [BreakPoints.mobile]: {
      fontSize: '24px',
    },
    fontWeight: FontWeight.semiBold,
    fontFamily: FontFamily.sans,
  },
  buttonSemiBold: {
    fontSize: '32px',
    [BreakPoints.mobile]: {
      fontSize: '24px',
    },
    fontWeight: FontWeight.semiBold,
    fontFamily: FontFamily.sans,
  },
  h1bold: {
    fontSize: '60px',
    [BreakPoints.mobile]: {
      fontSize: '56px',
    },
    fontWeight: FontWeight.bold,
    fontFamily: FontFamily.sans,
  },
  h2bold: {
    fontSize: '48px',
    [BreakPoints.mobile]: {
      fontSize: '32px',
    },
    fontWeight: FontWeight.bold,
    fontFamily: FontFamily.sans,
  },
  h3bold: {
    fontSize: '36px',
    [BreakPoints.mobile]: {
      fontSize: '24px',
    },
    fontWeight: FontWeight.bold,
    fontFamily: FontFamily.sans,
    display: 'block',
  },
  buttonBold: {
    fontSize: '32px',
    [BreakPoints.mobile]: {
      fontSize: '24px',
    },
    fontWeight: FontWeight.bold,
    fontFamily: FontFamily.sans,
  },
  bodyLarge: {
    fontSize: '28px',
    [BreakPoints.mobile]: {
      fontSize: '20px',
    },
    fontFamily: FontFamily.sans,
  },
  bodySmall: {
    fontSize: '20px',
    [BreakPoints.mobile]: {
      fontSize: '16px',
    },
    fontFamily: FontFamily.sans,
  },
  caption: {
    fontSize: '16px',
    [BreakPoints.mobile]: {
      fontSize: '12px',
    },
    fontFamily: FontFamily.sans,
  },
  overline: {
    fontSize: '8px',
    fontFamily: FontFamily.sans,
  },
};
